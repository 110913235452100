import type { ContentMetadata, CountryRestrictionType, MIABPageProps, MIABUserContext } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaButton } from "@/components/general/GaButton";
import { Language } from "@utils/type/type";
import { GaFormFieldInputText } from "@/components/form/GaFormFieldInputText";
import { addToast } from "@/components/general/GaToast";
import { useCustomModal } from "@/components/general/GaModal";
import { bookmarkMiab } from "@generated/api/mIABControllerApi";
import type { Store } from "@/common/storeUtils";
import { trackOutboundLinkClick, trackShareEvent } from "@generated/api/analyticTrackingControllerApi";
import { getPageViewMeta } from "@/components/analytic/_outbound-link-tracking";
import Arc from "./arc.svg?url";
import FancyShapes from "./fancyShapes.svg?url";
import MobileShapes from "./evenFancierShapes.svg?url";
import SpeechBubble from "./speechBubble.svg?url";
import { GaBadge } from "@/components/general/GaBadge";
import { getShareLinks } from "@/common/shareUtils";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";
import { ContentCard, type TranslationFnAsync } from "@/components/cards/ContentCard";
import { GaTooltip } from "@/components/general/GaTooltip";
import { dictToList } from "@utils/dictUtils";

async function copyToClipboard(miabLink: string, successMessage: string, dataId: bigint): Promise<void> {
  await navigator.clipboard.writeText(miabLink);
  addToast({
    message: successMessage,
  });
  await trackShareEvent({ id: dataId, contentItemType: "MIAB", shareMedium: "COPY_LINK" });
}

async function openPopup(summary: ContentMetadata, t: TranslationFn, portal: string | null, prospectView: boolean, userContext: MIABUserContext | null, translationFnAsync: Store<TranslationFnAsync>): Promise<void> {
  const miabLink = new URL(window.location.origin + "/" + Language.getCurrentLanguage() + (prospectView ? "/free-miab/" : "/miab/") + summary.contentId.id + (portal !== null ? "?u=" + portal : ""), window.location.origin);
  if (userContext !== null) {
    miabLink.searchParams.set("hash", userContext.hash);
  }
  await useCustomModal()({
    body: () => (
      <div class="miab-shareModal">
        <div class="miab-shareModal__description">
          <h3>{t("miab:page.shareModal.title")}</h3>
          <p>{t("miab:page.shareModal.description")}</p>
        </div>
        <div class="miab-shareModal__summary">
          <ContentCard content={summary} link={miabLink.toString()} layout={"vertical"} translationFn={translationFnAsync} />
        </div>
        <div class="miab-shareModal__shareLink">
          <p class="caption">{t("miab:page.shareModal.linkCaption")}</p>
          <GaFormFieldInputText name="miabShareLink" value={miabLink.href} onUpdateValue={() => void 0} disabled={true} />
          <div class="miab-shareModal__shareLinkButton">
            <GaButton onClick={() => copyToClipboard(miabLink.href, t("miab:page.shareModal.copySuccessful") ?? "", summary.contentId.id)}>{t("miab:page.shareModal.copyLink")}</GaButton>
          </div>
        </div>
      </div>
    ),
    size: "lg",
  });
}

async function bookmark(dataId: bigint, bookmarkStore: Store<boolean>): Promise<void> {
  bookmarkStore.set((await bookmarkMiab(Number(dataId))).bookmarked);
}

async function trackScheduleEventLink(dataId: bigint, url: string, targetName: string): Promise<void> {
  const meta = getPageViewMeta();
  if (meta !== null) {
    await trackOutboundLinkClick({ id: Number(dataId), targetUrl: url, targetName: targetName, type: "MIAB_SCHEDULE", meta: meta });
  }
}

function getTooltip(data: Record<string, string[]>, restriction: CountryRestrictionType, t: TranslationFn): string {
  return (
    t("miab:page.regionalRestriction." + restriction.toLowerCase()) +
    "<br>" +
    dictToList(data)
      .map(([key, value]) => {
        return t("general:region." + key) + "<br>" + value.join(", ");
      })
      .join("<br><br>")
  );
}

export function Header(props: { pageProps: MIABPageProps; bookmarkStore: Store<boolean>; t: TranslationFn; translationFnAsync: Store<TranslationFnAsync> }): GaVueComponent {
  const { currentLanguage, asp } = useGaContext();
  const t = props.t;
  const { miab } = props.pageProps;

  function getDescription(provider: string): string {
    const link =
      window.location.origin +
      "/" +
      currentLanguage() +
      (props.pageProps.prospectView ? "/free-miab/" : "/miab/") +
      miab.metadata.contentId.id +
      "?utm_medium=Calendar&utm_content=MIAB&utm_source=" +
      provider +
      (props.pageProps.userPortal !== null ? "&u=" + props.pageProps.userPortal : "") +
      (miab.userContext !== null ? "&hash=" + miab.userContext.hash : "");
    return t("miab:addToCalendar.description", [miab.metadata.common.title, link]) ?? "";
  }

  const title = t("miab:addToCalendar.title", [miab.metadata.common.title]) ?? "";
  const links = getShareLinks(title, (provider) => getDescription(provider));
  const pdfLink = props.pageProps.prospectView
    ? `/free-miab/${miab.metadata.contentId.id}/pdf`
    : `/miab/${miab.metadata.contentId.id}/pdf` + (miab.userContext !== null ? `?hash=${miab.userContext.hash}&customerId=${miab.userContext.customerId}` : "");
  const tooltip = getTooltip(props.pageProps.regionalRestriction.countriesByRegion.additionalProperties, props.pageProps.regionalRestriction.restrictionType, t);
  return (
    <>
      <div class="miab-header__image">
        <img src={miab.metadata.common.coverUri} class="img-fluid miab-header__image-img" alt={miab.metadata.common.title + " cover"} />
      </div>
      <div class="miab-header__title">
        <div>
          <div class="mb-1 miab-badge--light">
            <GaBadge variant="orange-light">{t("miab:page.header.badge")}</GaBadge>
            {miab.userContext !== null ? (
              <GaBadge variant="orange-light">
                <i class="ico-user-pen" />
                {t("miab:page.badge.customized")}
              </GaBadge>
            ) : null}
          </div>
          <h1 class="miab-header__title-h1">{miab.metadata.common.title}</h1>
        </div>
        {props.pageProps.regionalRestriction.restrictionType !== "NO_RESTRICTION" ? (
          <div>
            <GaTooltip title={tooltip} htmlEnabled={true}>
              <span class="small mb-2 d-inline-block">
                <i class="ico-exclamation-circle"></i>&nbsp;{t("miab:page.regionalRestriction")}
              </span>
            </GaTooltip>
          </div>
        ) : null}
      </div>
      <div class="d-flex gap-3 flex-wrap miab-header__action">
        <div class="dropdown">
          <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
            <i class="ico-calendar" />
            &nbsp;{t("miab:page.actions.scheduleGroupDiscussion")}
          </button>
          <ul class="dropdown-menu">
            {links.map((shareLink) => (
              <li>
                <a class="dropdown-item" href={shareLink.href} onClick={() => trackScheduleEventLink(miab.metadata.contentId.id, shareLink.href, shareLink.title)} target="_blank">
                  {shareLink.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <GaButton onClick={() => openPopup(miab.metadata, t, asp?.username ?? null, props.pageProps.prospectView, miab.userContext, props.translationFnAsync)}>
          <i class="ico-share-alt2" />
          &nbsp;{t("miab:page.actions.share")}
        </GaButton>
        {props.pageProps.prospectView ? (
          ""
        ) : (
          <GaButton onClick={() => bookmark(miab.metadata.contentId.id, props.bookmarkStore)}>
            {props.bookmarkStore.get() ? <i class="ico-bookmark-fill" /> : <i class="ico-bookmark" />}
            &nbsp;{props.bookmarkStore.get() ? t("miab:page.actions.saved") : t("miab:page.actions.save")}
          </GaButton>
        )}

        <GaButton variant="primary-alt" target="_blank" href={pdfLink}>
          <i class="ico-download" />
          &nbsp;{t("miab:page.actions.pdf")}
        </GaButton>
      </div>
      <div class="miab-header__background">
        <img src={Arc} alt="getAbstract Arc" class="miab-header__arc" />
        <img src={FancyShapes} alt="Some fancy shapes for visual experience" class="miab-header__shapes" />
        <img src={MobileShapes} alt="Some fancy shapes for mobile" class="miab-header__shapesMobile" />
        <img src={SpeechBubble} alt="Speech bubble" class="miab-header_speechBubble" />
      </div>
    </>
  );
}
